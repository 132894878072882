import React from 'react'
import './index.css'

function Header() {
    return (
        <header className="fixed-navbar">
            <div className='nav-box'>
                <ul className="nav-items">
                    <li className="nav-item" id="home"><a href="#header-section">Home</a></li>
                    <li className="nav-item" id="projects"><a href="#projects-section">Projects</a></li>
                    {/* <li className="nav-item" id="highlights"><a href="#work-experience">Highlights</a></li> */}
                    <li className="nav-item" id="contact"><a href="#contact-section">Contacts</a></li>
                </ul>
            </div>
        </header>
    )
}

export default Header